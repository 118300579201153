import {HttpClient} from '@angular/common/http';
import {Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {EventMessageType} from '../models/event-message-type.enum';
import {MessageHandlerService} from './messageHandler.service';
import {SseEventsService} from './sse-events.service';
import {environment} from '../../../environments/environment';
import {EasytrackNotification, EasytrackNotificationType, ToastService} from 'bs-components';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly _notifications$: WritableSignal<EasytrackNotification[]> = signal([]);

  private readonly serviceUrl: string = `${environment.baseUrl}/notifications`;

  private readonly audio: HTMLAudioElement = new Audio('assets/audios/notification.mp3');

  constructor(
    private readonly http: HttpClient,
    private readonly messageService: MessageHandlerService,
    private readonly translate: TranslateService,
    private readonly sseService: SseEventsService,
    private readonly toastService: ToastService
  ) {
    this.sseService
      .onSubject(EventMessageType.NEW_NOTIFICATION)
      .subscribe((event) => {
        this.addNotification(event.data);
        this.playNotificationAudio();
      });
  }

  public getNotifications(): Observable<EasytrackNotification[]> {
    return this.http.get<EasytrackNotification[]>(this.serviceUrl).pipe(
      tap((notifications) => {
        this._notifications$.set(notifications);
      }),
      catchError(
        this.messageService.handleError<EasytrackNotification[]>(
          this.translate.instant('LOGS.NOTIFICATIONS.GET_ALL_FAILURE'),
          []
        )
      )
    );
  }

  public read(id: number): Observable<EasytrackNotification | undefined> {
    return this.http
      .put<EasytrackNotification | undefined>(
        `${this.serviceUrl}/${id}/read`,
        {}
      )
      .pipe(
        catchError(
          this.messageService.handleError<EasytrackNotification>(
            this.translate.instant('LOGS.NOTIFICATIONS.TOGGLE_SEEN_FAILURE')
          )
        )
      );
  }

  public addNotification(notification: EasytrackNotification): void {
    this._notifications$.set([notification, ...this._notifications$()]);
    this.toastService.showInfo(notification.title, notification.text);
    this.playNotificationAudio();
  }

  public generateFakeNotification() {
    return this.http.post(this.serviceUrl, {
      notifType: EasytrackNotificationType.DEFAULT,
      text: 'Cette notif a été générée à la main !',
      title: 'NOTIFICATION GENEREE',
    });
  }

  private playNotificationAudio() {
    if (this.audio.paused) {
      this.audio.play();
      setTimeout(() => {
        this.audio.pause();
        this.audio.currentTime = 0;
      }, 1000);
    }
  }

  //  ----------------------------------------------- ACCESSORS ---------------------------------------------------------------- //

  public get notifications$(): Signal<EasytrackNotification[]> {
    return this._notifications$.asReadonly();
  }
}
